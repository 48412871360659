import Axios from "@/misc/axios.js";
import toast from "@/misc/toast.js";
import router from "@/misc/router.js";
import TenantConfigHandler from "@/misc/TenantConfigHandler";
import Utils from "../../misc/Utils";
import store from "@/misc/vuex-store.js";
import remFormUtils from "../../misc/remFormUtils";

export default {
  asyncGet: async function (state, buildingId /*, formData*/) {
    const response = await Utils.fetchBuildingById(buildingId);

    if (response) {
      state.value.basicData.forEach((e) => {
        let model =
          (e.model + "").charAt(0).toUpperCase() + (e.model + "").slice(1);
        let responsibleModel = 
          (e.title + "").charAt(0).toUpperCase() + (e.title + "").slice(1);
        if (e.type == "option") {
          e.value = response[`basicData${model}Id`];
          e.valueLabel = response[`basicData${model}Value`];
        }else if(e.type=="optionMultiSelect"){
          e.value = response[`basicData${model}Ids`];
          e.valueLabel = response[`basicData${model}Values`];
        }else if(e.type=="optionSearchEndpoint"){
          e.value = response[`basicData${responsibleModel}Id`];
          e.valueLabel = response[`basicData${responsibleModel}Name`];
        }else {
          e.value = response[`basicData${model}`];
        }
      });

      state.value.structureTypesData.forEach((e) => {
        if (e.type == "label") return;
        if (e.type == "option") {
          e.value = response[e.model + "Id"];
          e.valueLabel = response[e.model + "Value"];
        } else if(e.type=="optionMultiSelect"){
          e.value = response[e.model + "Ids"];
          e.valueLabel = response[e.model + "Values"];
        } else {
          e.value = response[e.model];
        }
      });
    }
  },
  saveBuilding: async function (state, t, propertyId, buildingId, formData) {
    const hermes = {};

    state.value.structureTypesData.forEach((e) => {
      hermes[e.model] = e.value;
    });

    hermes.basicData = {};
    state.value.basicData.forEach((e) => {
      if(e.type=="optionMultiSelect"){
        hermes.basicData[e.model + "s"] = e.value == "" ? null : e.value;
      }else{
        hermes.basicData[e.model] = e.value == "" ? null : e.value;
      }
      
      
    });
    hermes.property = propertyId;

    if (buildingId != 0) {
      await Axios.put(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
          "/rem/building/" +
          buildingId,
        hermes,
        {
          onTokenRefreshed: () => {
            this.saveBuilding(state, t, propertyId, buildingId);
          },
        }
      ).then(async (resp) => {
        if (resp) {
          if (resp.status == 200) {
            toast.success(t("message.successfulSave"));
            await this.asyncGet(state, buildingId, formData);
            state.value.structEdit = false;
            state.value.basicDataEdit = false;
            store.commit("setRemFormEdit", false);
          } else {
            toast.error(t("message.notSuccessfulSave"));
          }
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
      });
    }
  },
  uploadFile: async function (
    state,
    docsInput,
    t,
    buildingId,
    buildingDocumentsGrid,
    allowedExtensions
  ) {
    const uri =
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
      `/rem/building/${buildingId}/document`;

    const formData = new FormData();
    formData.append("mainType", state.value.selectedMainType);
    formData.append("subType", state.value.selectedSubType);
    formData.append("document", docsInput.value.files[0]);
    formData.append("name", state.value.docuName);
    formData.append("dateOfOrigin", state.value.docuOriginDate);
    formData.append("contractIdentifier", state.value.contractIdentifier);

    try {
      const resp = await Axios.post(uri, formData);
      if (resp.status == 200) {
        state.value.docuName = "";
        state.value.docuOriginDate = "";
        state.value.selectedMainType = "";
        state.value.selectedSubType = "";
        state.value.contractIdentifier = "";
        toast.success(t("message.successfulSave"));
      } else {
        toast.error(t("message.saveNotSuccessful"));
      }
    } catch (error) {
      console.log(error);
      if (error.response.status == 406) {
        toast.error(
          t("message.fileFormatError") +
            allowedExtensions.value +
            t("message.fileFormatError2")
        );
      } else if (error.response.status == 413) {
        toast.error(t("message.fileSizeError"));
      } else {
        toast.error(t("message.saveError"));
      }
    }

    buildingDocumentsGrid?.value?.refresh();
  },
  saveMeter: async function (state, buildingId, t) {
    const hermes = {
      buildingId: buildingId,
      gistId: "",
      type: "",
      utilityType: "",
      identifier: "",
      placement: "",
      readings: "",
      readingFrequency: "",
      commType: "",
      expirationDate: "",
      partnerId: "",
    };

    state.value.meterData.forEach((e) => {
      hermes[e.model] = e.value == "" ? null : e.value;
    });

    return await remFormUtils.putPost(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/meter",
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/meter/" +
        state.value.meterId,
      hermes,
      "meter",
      4,
      state,
      t
    );
  },
  saveUtilization: async function (state, buildingId, t) {
    const hermes = {
      buildingId: buildingId,
      floorArea: "",
      legalRelationship: "",
      legalRelationshipStart: "",
      legalRelationshipEnd: "",
      partnerId: "",
    };

    state.value.utilizationData.forEach((e) => {
      hermes[e.model] = e.value == "" ? null : e.value;
    });

    return await remFormUtils.putPost(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/utilization",
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/utilization/" +
        state.value.utilizationId,
      hermes,
      "utilization",
      3,
      state,
      t
    );
  },
  savePremise: async function (state, t, buildingId) {
    const hermes = {
      buildingId: buildingId,
      partnerIds: [],
      basicData: {},
      specialData: {},
    };

    state.value.premiseData.forEach((e) => {
      if (e.type == "label") return;
      let model = e.model.replace("basicData", "");
      model = model.charAt(0).toLowerCase() + model.slice(1);
      hermes.basicData[model] = e.value == "" ? null : e.value;
    });

    state.value.staircaseData.forEach((e) => {
      if (e.type == "label") return;
      let model = e.model.replace("basicData", "");
      model = model.charAt(0).toLowerCase() + model.slice(1);
      hermes.basicData[model] = e.value == "" ? null : e.value;
    });
    state.value.elevatorData.forEach((e) => {
      if (e.type == "label") return;
      let model = e.model.replace("basicData", "");
      model = model.charAt(0).toLowerCase() + model.slice(1);
      hermes.basicData[model] = e.value == "" ? null : e.value;
    });
    state.value.premiseSpecialData.forEach((e) => {
      if (e.type == "label") {
        return;
      } else {
        hermes.specialData[e.model] = e.value == "" ? null : e.value;
      }
    });

    return await remFormUtils.putPost(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/premise",
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/premise/" +
        state.value.premiseId,
      hermes,
      "premise",
      1,
      state,
      t
    );
  },
  saveLegalsit: async function (state, t, buildingId) {
    const hermes = {
      /*
      buildingId: buildingId,
      legalSituationType: "",
      legalRelationshipType: "",
      ownership: "",
      legalRelationshipStart: "",
      legalRelationshipEnd: "",
      floorArea: "",*/
    };

    state.value.legalsitData.forEach((e) => {
      hermes[e.model] = e.value == "" ? null : e.value;
    });
    hermes.buildingId = buildingId;

    console.log(hermes);

    return await remFormUtils.putPost(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/legal-situation",
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/legal-situation/" +
        state.value.legalsitId,
      hermes,
      "legalsit",
      2,
      state,
      t
    );
  },
  toPremise: function (id) {
    router.push({
      name: "premise-data",
      params: { premiseId: id },
    });
    store.commit("setRemTab", 1);
  },
  savePartner: async function (state, t, id) {
    const hermes = {
      partnerMainType: 0,
      partnerSubType: 0,
      name: "",
      taxId: "",
      taxNumber: "",
    };

    state.value.partnerData.forEach((e) => {
      if (e.model != "") {
        hermes[e.model] = e.value == "" ? null : e.value;
      }
    });

    return await remFormUtils.putPost(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        `/rem/building/${id}/partner`,
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        `/rem/partner/` +
        state.value.partnerId,
      hermes,
      "partner",
      13,
      state,
      t
    );
  },
};
