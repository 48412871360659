import { computed } from "vue";import store from "@/misc/vuex-store";
import Axios from "@/misc/axios";
import TenantConfigHandler from '@/misc/TenantConfigHandler';
const language = computed(() => store.getters.getLanguage);


export default {
  btoa_utf8(value) {
    return btoa(
      String.fromCharCode(
        ...new TextEncoder('utf-8').encode(value)
      )
    );
  },
  atob_utf8(value) {
    const value_latin1 = atob(value);
    return new TextDecoder('utf-8').decode(
      Uint8Array.from(
        {
          length: value_latin1.length 
        },
        (element, index) => value_latin1.charCodeAt(index)
      )
    )
  },
  async getFormatterMappingForOptionValue(optionType) {
    const optionValues = await Axios.get(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
      "/rem/option/options/" +
      optionType,
      {
        'Accept-Language': language.value
      },
      {
        onTokenRefreshed: () => {
          this.getFormatterMappingForOptionValue(optionType);
        },
      }
    );
    const formatterMapping = {
      "null": {title: "N/A"}
    };
    optionValues.data.forEach(propertyType => {
      formatterMapping[propertyType.originalValue] = { title: propertyType.value };
    });
    
    return formatterMapping;
  },
  async getFormatterMappingForEnum() {
    const optionValues = await Axios.get(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
      "/rem/workflow/task/type",
      {
        onTokenRefreshed: () => {
          this.getFormatterMappingForEnum();
        },
      } 
    );
    const formatterMapping = {};
    optionValues.data.content.forEach(propertyType => {
      formatterMapping[propertyType.name] = { title: propertyType.name };
    });
    return formatterMapping;
  },
}