import { computedAsync } from '@vueuse/core'
import RVGUtils from "../../../RVG/RobberVueGridUtils.js";

// RVG congigs
import propertiesGridConfig from "@/misc/gridConfigs/workflowPage/propertySelector/propertiesGridConfig.js"
import propertiesGridConfigSelected from '@/misc/gridConfigs/workflowPage/propertySelector/propertiesGridConfigSelected.js';
import propertiesGridConfigSelector from '@/misc/gridConfigs/workflowPage/propertySelector/propertiesGridConfigSelector.js';
import buildingsGridConfig from '@/misc/gridConfigs/workflowPage/propertySelector/buildingsGridConfig.js';
import buildingsSelectedGridConfig from '@/misc/gridConfigs/workflowPage/propertySelector/buildingsSelectedGridConfig.js';
import buildingsSelectorGridConfig from '@/misc/gridConfigs/workflowPage/propertySelector/buildingsSelectorGridConfig.js';
import premiseGridConfig from '@/misc/gridConfigs/workflowPage/propertySelector/premiseGridConfig.js';
import premiseGridConfigSelected from '@/misc/gridConfigs/workflowPage/propertySelector/premiseGridConfigSelected.js';
import premiseGridConfigSelector from '@/misc/gridConfigs/workflowPage/propertySelector/premiseGridConfigSelector.js';

export default {

    getPropertyBasicConfig: (state, t, router, endpoint) => {
        const computed = computedAsync(async () => {

            const [
                basicDataTypeMapping,
                basicDataClassificationMapping,
                basicDataStatusMapping,
            ] = await Promise.all([
                RVGUtils.getFormatterMappingForOptionValue("PROPERTY_TYPE"),
                RVGUtils.getFormatterMappingForOptionValue("PROPERTY_CLASSIFICATION"),
                RVGUtils.getFormatterMappingForOptionValue("PROPERTY_STATUS"),
            ])
        
            return propertiesGridConfig(state, t, router, endpoint, basicDataTypeMapping, basicDataClassificationMapping, basicDataStatusMapping);
        }, propertiesGridConfig(state, t, router, endpoint))
        return computed
    },

    getPropertySelectedConfig: (state, t, router, endpoint) => {
        const computed = computedAsync(async () => {

            const [
                basicDataTypeMapping,
                basicDataClassificationMapping,
                basicDataStatusMapping,
            ] = await Promise.all([
                RVGUtils.getFormatterMappingForOptionValue("PROPERTY_TYPE"),
                RVGUtils.getFormatterMappingForOptionValue("PROPERTY_CLASSIFICATION"),
                RVGUtils.getFormatterMappingForOptionValue("PROPERTY_STATUS"),
            ])
        
            return propertiesGridConfigSelected(state, t, router, endpoint, basicDataTypeMapping, basicDataClassificationMapping, basicDataStatusMapping);
        }, propertiesGridConfigSelected(state, t, router, endpoint))
        return computed
    },

    getPropertySelectorConfig: (state, t, router, endpoint) => {
        endpoint
        const computed = computedAsync(async () => {
            const [
                basicDataTypeMapping,
                basicDataClassificationMapping,
                basicDataStatusMapping,
            ] = await Promise.all([
                RVGUtils.getFormatterMappingForOptionValue("PROPERTY_TYPE"),
                RVGUtils.getFormatterMappingForOptionValue("PROPERTY_CLASSIFICATION"),
                RVGUtils.getFormatterMappingForOptionValue("PROPERTY_STATUS"),
            ])
        
            return propertiesGridConfigSelector(state, t, router, endpoint, basicDataTypeMapping, basicDataClassificationMapping, basicDataStatusMapping);
        }, propertiesGridConfigSelector(state, t, router, endpoint))
        return computed
    },

    getBuildingBasicConfig: (state, t, router, endpoint) => {
        const buildingsGridConfigComputed = computedAsync(async () => {
            const [
                basicDataMainFunctionMapping,
                basicDataStatusMapping,
                basicDataSapMapping,
                basicDataFoundationMapping,
                basicDataBearingMasonryMapping,
                basicDataSlabStructureInterMapping,
                basicDataSlabSubStructureInterMapping,
                basicDataSlabStructureClosingMapping,
                basicDataSlabSubStructureClosingMapping,
                basicDataFlatRoofMapping,
                basicDataRoofingMapping,
                basicDataRoofTypeMapping,
                basicDataInsulationMapping
            ] = await Promise.all([
                RVGUtils.getFormatterMappingForOptionValue("BUILDING_MAIN_FUNCTION"),
                RVGUtils.getFormatterMappingForOptionValue("BUILDING_STATUS"),
                RVGUtils.getFormatterMappingForOptionValue("SAP_BUILDING_IDENTIFIER"),
                RVGUtils.getFormatterMappingForOptionValue("FOUNDATION"),
                RVGUtils.getFormatterMappingForOptionValue("BEARING_MASONRY"),
                RVGUtils.getFormatterMappingForOptionValue("SLAB_STRUCTURE_INTERMEDIATE"),
                RVGUtils.getFormatterMappingForOptionValue("SLAB_SUB_STRUCTURE_INTERMEDIATE"),
                RVGUtils.getFormatterMappingForOptionValue("SLAB_STRUCTURE_CLOSING"),
                RVGUtils.getFormatterMappingForOptionValue("SLAB_SUB_STRUCTURE_CLOSING"),
                RVGUtils.getFormatterMappingForOptionValue("FLAT_ROOF"),
                RVGUtils.getFormatterMappingForOptionValue("ROOFING"),
                RVGUtils.getFormatterMappingForOptionValue("ROOF_TYPE"),
                RVGUtils.getFormatterMappingForOptionValue("INSULATION"),
            ])
    
            return buildingsGridConfig(
                state,
                t,
                router,
                endpoint,
                basicDataMainFunctionMapping,
                basicDataStatusMapping,
                basicDataSapMapping,
                basicDataFoundationMapping,
                basicDataBearingMasonryMapping,
                basicDataSlabStructureInterMapping,
                basicDataSlabSubStructureInterMapping,
                basicDataSlabStructureClosingMapping,
                basicDataSlabSubStructureClosingMapping,
                basicDataFlatRoofMapping,
                basicDataRoofingMapping,
                basicDataRoofTypeMapping,
                basicDataInsulationMapping
            );
        }, buildingsGridConfig(
            state,
            t,
            router,
            endpoint,
        ));

        return buildingsGridConfigComputed;
    
    },

    getBuildingSelectorConfig: (state, t, router, endpoint) => {
        const buildingsGridConfigComputed = computedAsync(async () => {
            const [
                basicDataMainFunctionMapping,
                basicDataStatusMapping,
                basicDataSapMapping,
                basicDataFoundationMapping,
                basicDataBearingMasonryMapping,
                basicDataSlabStructureInterMapping,
                basicDataSlabSubStructureInterMapping,
                basicDataSlabStructureClosingMapping,
                basicDataSlabSubStructureClosingMapping,
                basicDataFlatRoofMapping,
                basicDataRoofingMapping,
                basicDataRoofTypeMapping,
                basicDataInsulationMapping
            ] = await Promise.all([
                RVGUtils.getFormatterMappingForOptionValue("BUILDING_MAIN_FUNCTION"),
                RVGUtils.getFormatterMappingForOptionValue("BUILDING_STATUS"),
                RVGUtils.getFormatterMappingForOptionValue("SAP_BUILDING_IDENTIFIER"),
                RVGUtils.getFormatterMappingForOptionValue("FOUNDATION"),
                RVGUtils.getFormatterMappingForOptionValue("BEARING_MASONRY"),
                RVGUtils.getFormatterMappingForOptionValue("SLAB_STRUCTURE_INTERMEDIATE"),
                RVGUtils.getFormatterMappingForOptionValue("SLAB_SUB_STRUCTURE_INTERMEDIATE"),
                RVGUtils.getFormatterMappingForOptionValue("SLAB_STRUCTURE_CLOSING"),
                RVGUtils.getFormatterMappingForOptionValue("SLAB_SUB_STRUCTURE_CLOSING"),
                RVGUtils.getFormatterMappingForOptionValue("FLAT_ROOF"),
                RVGUtils.getFormatterMappingForOptionValue("ROOFING"),
                RVGUtils.getFormatterMappingForOptionValue("ROOF_TYPE"),
                RVGUtils.getFormatterMappingForOptionValue("INSULATION"),
            ])
    
            return buildingsSelectorGridConfig(
                state,
                t,
                router,
                endpoint,
                basicDataMainFunctionMapping,
                basicDataStatusMapping,
                basicDataSapMapping,
                basicDataFoundationMapping,
                basicDataBearingMasonryMapping,
                basicDataSlabStructureInterMapping,
                basicDataSlabSubStructureInterMapping,
                basicDataSlabStructureClosingMapping,
                basicDataSlabSubStructureClosingMapping,
                basicDataFlatRoofMapping,
                basicDataRoofingMapping,
                basicDataRoofTypeMapping,
                basicDataInsulationMapping
            );
        }, buildingsSelectorGridConfig(
            state,
            t,
            router,
            endpoint,
        ));

        return buildingsGridConfigComputed;
    
    },

    getBuildingSelectedConfig: (state, t, router, endpoint) => {
        const buildingsGridConfigComputed = computedAsync(async () => {
            const [
                basicDataMainFunctionMapping,
                basicDataStatusMapping,
                basicDataSapMapping,
                basicDataFoundationMapping,
                basicDataBearingMasonryMapping,
                basicDataSlabStructureInterMapping,
                basicDataSlabSubStructureInterMapping,
                basicDataSlabStructureClosingMapping,
                basicDataSlabSubStructureClosingMapping,
                basicDataFlatRoofMapping,
                basicDataRoofingMapping,
                basicDataRoofTypeMapping,
                basicDataInsulationMapping
            ] = await Promise.all([
                RVGUtils.getFormatterMappingForOptionValue("BUILDING_MAIN_FUNCTION"),
                RVGUtils.getFormatterMappingForOptionValue("BUILDING_STATUS"),
                RVGUtils.getFormatterMappingForOptionValue("SAP_BUILDING_IDENTIFIER"),
                RVGUtils.getFormatterMappingForOptionValue("FOUNDATION"),
                RVGUtils.getFormatterMappingForOptionValue("BEARING_MASONRY"),
                RVGUtils.getFormatterMappingForOptionValue("SLAB_STRUCTURE_INTERMEDIATE"),
                RVGUtils.getFormatterMappingForOptionValue("SLAB_SUB_STRUCTURE_INTERMEDIATE"),
                RVGUtils.getFormatterMappingForOptionValue("SLAB_STRUCTURE_CLOSING"),
                RVGUtils.getFormatterMappingForOptionValue("SLAB_SUB_STRUCTURE_CLOSING"),
                RVGUtils.getFormatterMappingForOptionValue("FLAT_ROOF"),
                RVGUtils.getFormatterMappingForOptionValue("ROOFING"),
                RVGUtils.getFormatterMappingForOptionValue("ROOF_TYPE"),
                RVGUtils.getFormatterMappingForOptionValue("INSULATION"),
            ])
    
            return buildingsSelectedGridConfig(
                state,
                t,
                router,
                endpoint,
                basicDataMainFunctionMapping,
                basicDataStatusMapping,
                basicDataSapMapping,
                basicDataFoundationMapping,
                basicDataBearingMasonryMapping,
                basicDataSlabStructureInterMapping,
                basicDataSlabSubStructureInterMapping,
                basicDataSlabStructureClosingMapping,
                basicDataSlabSubStructureClosingMapping,
                basicDataFlatRoofMapping,
                basicDataRoofingMapping,
                basicDataRoofTypeMapping,
                basicDataInsulationMapping
            );
        }, buildingsSelectedGridConfig(
            state,
            t,
            router,
            endpoint
        ));

        return buildingsGridConfigComputed;
    
    },

    getPremiseBasicConfig: (state, t, router, endpoint) => {
        const premiseGridConfigComputed = computedAsync(async () => {
            const [
                basicDataPremiseFunctionMapping,
                basicDataCremClassMapping,
                basicDataStairwayTypeMapping,
                basicDataElevatorTypeMapping,
                basicDataFloorMapping,
                specialDataFloorCoveringMapping,
                specialDataFalseCeilingMapping,
                specialDataDoorTypeMapping,
                specialDataWindowMaterialMapping,
                specialDataSzepaMapping,
            ] = await Promise.all([
                RVGUtils.getFormatterMappingForOptionValue("PREMISE_FUNCTION"),
                RVGUtils.getFormatterMappingForOptionValue("CREM_CLASSIFICATION"),
                RVGUtils.getFormatterMappingForOptionValue("STAIRWAY_TYPE"),
                RVGUtils.getFormatterMappingForOptionValue("ELEVATOR_TYPE"),
                RVGUtils.getFormatterMappingForOptionValue("PREMISE_FLOOR"),
                RVGUtils.getFormatterMappingForOptionValue("FLOOR_COVERING"),
                RVGUtils.getFormatterMappingForOptionValue("FALSE_CEILING"),
                RVGUtils.getFormatterMappingForOptionValue("DOOR_TYPE"),
                RVGUtils.getFormatterMappingForOptionValue("WINDOW_MATERIAL"),
                RVGUtils.getFormatterMappingForOptionValue("SZEPA_CLASSIFICATION"),
            ])
    
            return premiseGridConfig(
                state,
                t,
                router,
                endpoint,
                basicDataPremiseFunctionMapping,
                basicDataCremClassMapping,
                basicDataStairwayTypeMapping,
                basicDataElevatorTypeMapping,
                basicDataFloorMapping,
                specialDataFloorCoveringMapping,
                specialDataFalseCeilingMapping,
                specialDataDoorTypeMapping,
                specialDataWindowMaterialMapping,
                specialDataSzepaMapping,
            );
        }, premiseGridConfig(
            state,
            t,
            router,
            endpoint,
        ));
    
        return premiseGridConfigComputed;
    
    },

    getPremiseSelectedConfig: (state, t, router, endpoint) => {
        const premiseGridConfigComputed = computedAsync(async () => {
            const [
                basicDataPremiseFunctionMapping,
                basicDataCremClassMapping,
                basicDataStairwayTypeMapping,
                basicDataElevatorTypeMapping,
                basicDataFloorMapping,
                specialDataFloorCoveringMapping,
                specialDataFalseCeilingMapping,
                specialDataDoorTypeMapping,
                specialDataWindowMaterialMapping,
                specialDataSzepaMapping,
            ] = await Promise.all([
                RVGUtils.getFormatterMappingForOptionValue("PREMISE_FUNCTION"),
                RVGUtils.getFormatterMappingForOptionValue("CREM_CLASSIFICATION"),
                RVGUtils.getFormatterMappingForOptionValue("STAIRWAY_TYPE"),
                RVGUtils.getFormatterMappingForOptionValue("ELEVATOR_TYPE"),
                RVGUtils.getFormatterMappingForOptionValue("PREMISE_FLOOR"),
                RVGUtils.getFormatterMappingForOptionValue("FLOOR_COVERING"),
                RVGUtils.getFormatterMappingForOptionValue("FALSE_CEILING"),
                RVGUtils.getFormatterMappingForOptionValue("DOOR_TYPE"),
                RVGUtils.getFormatterMappingForOptionValue("WINDOW_MATERIAL"),
                RVGUtils.getFormatterMappingForOptionValue("SZEPA_CLASSIFICATION"),
            ])
    
            return premiseGridConfigSelected(
                state,
                t,
                router,
                endpoint,
                basicDataPremiseFunctionMapping,
                basicDataCremClassMapping,
                basicDataStairwayTypeMapping,
                basicDataElevatorTypeMapping,
                basicDataFloorMapping,
                specialDataFloorCoveringMapping,
                specialDataFalseCeilingMapping,
                specialDataDoorTypeMapping,
                specialDataWindowMaterialMapping,
                specialDataSzepaMapping,
            );
        }, premiseGridConfigSelected(
            state,
            t,
            router,
            endpoint,
        ));
    
        return premiseGridConfigComputed;
    
    },

    getPremiseSelectorConfig: (state, t, router, endpoint) => {
        const premiseGridConfigComputed = computedAsync(async () => {
            const [
                basicDataPremiseFunctionMapping,
                basicDataCremClassMapping,
                basicDataStairwayTypeMapping,
                basicDataElevatorTypeMapping,
                basicDataFloorMapping,
                specialDataFloorCoveringMapping,
                specialDataFalseCeilingMapping,
                specialDataDoorTypeMapping,
                specialDataWindowMaterialMapping,
                specialDataSzepaMapping,
            ] = await Promise.all([
                RVGUtils.getFormatterMappingForOptionValue("PREMISE_FUNCTION"),
                RVGUtils.getFormatterMappingForOptionValue("CREM_CLASSIFICATION"),
                RVGUtils.getFormatterMappingForOptionValue("STAIRWAY_TYPE"),
                RVGUtils.getFormatterMappingForOptionValue("ELEVATOR_TYPE"),
                RVGUtils.getFormatterMappingForOptionValue("PREMISE_FLOOR"),
                RVGUtils.getFormatterMappingForOptionValue("FLOOR_COVERING"),
                RVGUtils.getFormatterMappingForOptionValue("FALSE_CEILING"),
                RVGUtils.getFormatterMappingForOptionValue("DOOR_TYPE"),
                RVGUtils.getFormatterMappingForOptionValue("WINDOW_MATERIAL"),
                RVGUtils.getFormatterMappingForOptionValue("SZEPA_CLASSIFICATION"),
            ])
    
            return premiseGridConfigSelector(
                state,
                t,
                router,
                endpoint,
                basicDataPremiseFunctionMapping,
                basicDataCremClassMapping,
                basicDataStairwayTypeMapping,
                basicDataElevatorTypeMapping,
                basicDataFloorMapping,
                specialDataFloorCoveringMapping,
                specialDataFalseCeilingMapping,
                specialDataDoorTypeMapping,
                specialDataWindowMaterialMapping,
                specialDataSzepaMapping,
            );
        }, premiseGridConfigSelector(
            state,
            t,
            router,
            endpoint,
        ));
    
        return premiseGridConfigComputed;
    
    }
}