<template>
    <div id="WFTaskTypes" class="h-screen max-h-screen">
        <BaseLayout>
            <template #leftButtons>
                <BackToMapButton />
            </template>

            <template #mainContent>
                <div class="h-full h-max-full mx-auto px-2  k">
                    <h2 class="text-lg font-sans font-semibold uppercase text-white my-8">{{
                        $t('message.taskTypes') }}
                    </h2>

                    <RemPrimaryButtonSmall class="mr-4" :label="$t('message.create')" :method="() => {
                        addNewTaskType()
                    }"></RemPrimaryButtonSmall>

                    <RobberVueGrid v-if="!state.forceRefresh" ref="taskTypesGrid" :config="taskTypesGridConfigComputed" />
                </div>

                <AddTaskTypeModal @refreshGrid="refreshGrid" :showModal="modalStatus" :taskTypesGrid="taskTypesGrid" :taskType="state.taskTypeById" :editMode="state.editMode"/>
            </template>
        </BaseLayout>
    </div>
</template>

<script setup>
import BaseLayout from '@/layouts/BaseLayout.vue'
import BackToMapButton from '../../components/BackToMapButton.vue'
import RobberVueGrid from "@/components/RVG/RobberVueGrid.vue";
import RemPrimaryButtonSmall from '../../components/REM/RemPrimaryButtonSmall.vue';
import AddTaskTypeModal from './AddTaskTypeModal.vue'

// utils
// import { computedAsync } from '@vueuse/core'
// import RVGUtils from "../../components/RVG/RobberVueGridUtils.js";
// import router from "@/misc/router";
import { ref, computed } from 'vue';
//import User from "../misc/User";
import Axios from '@/misc/axios';
import store from '@/misc/vuex-store'
import TenantConfigHandler from '@/misc/TenantConfigHandler';
import { useI18n } from "vue-i18n";
const { t } = useI18n();

// RVGS
import taskTypesGridConfig from "@/misc/gridConfigs/workflowAdmin/taskTypesGridConfig.js";

const taskTypesGrid = ref()

const state = ref({
    taskTypeById: null,
    editMode: false,
    forceRefresh: false,
})

const refreshGrid = () => {
  state.value.forceRefresh = true;
  setTimeout(() => {
    state.value.forceRefresh = false;
  }, 100);
};

const addNewTaskType = () => {
    state.value.editMode = false;
    store.commit('setIsModalOpen', true);
}

const editTaskType = async (id) => {
    const uri = TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/workflow/admin/task-type/${id}`;

    try {
        const response = await Axios.get(uri, {
            onTokenRefreshed: () => {
                editTaskType(id)
            }
        })
        state.value.taskTypeById = response.data;
           
    } catch (error) {
        console.log(error);
    }

    state.value.editMode = true;
}

const taskTypesGridConfigComputed = computed(() => {
    return taskTypesGridConfig(state, t, editTaskType);
});

const modalStatus = computed(() => {
  return store.getters.getIsModalOpen;
});
</script>

<style scoped></style>