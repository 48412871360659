

<template>
    <div>
        <div v-if="type == 'label'">
            <h2 class=" text-xl text-secondary w-full font-bold">{{ title }}</h2>
            <hr class="mb-8" />
        </div>
        <div v-else>
            <RemLabel v-model="inputValue" :mandatory="mandatory" :title="title" :helperText="helperText"></RemLabel>

            <!-- eslint-disable -->
            <RemFormInput v-model:inputValue="inputValue" v-model:parent="parent" v-model:validated="validated" v-model:options="options" :config="config" :disabled="disabled"></RemFormInput>
            <!-- eslint-enable -->
            

            
        </div>

    </div>


</template>

<script setup>
import RemFormInput from './REM/imFormInput/RemFormInput.vue';

import RemLabel from './REM/imFormInput/RemLabel.vue';



import { defineModel, watch } from 'vue';

const props = defineProps(['title', 'type', 'valueLabel', 'optionValue', 'endPoint', 'displayValue', 'searchable', 'manualOptions', 'onchangeMethod', 'validationMethod', 'optionSearchEndpoint', 'helperText', 'mandatory', 'disabled']);
const inputValue = defineModel('inputValue');
const parent = defineModel('parent')
const validated = defineModel('validated')
const options = defineModel('options')

let config = {
    title: props.title,
    type: props.type,
    valueLabel: props.valueLabel,
    optionValue: props.optionValue,
    endpoint: props.endPoint,
    displayValue: props.displayValue,
    searchable: props.searchable,
    manualOptions: props.manualOptions,
    onchangeMethod: props.onchangeMethod,
    validationMethod: props.validationMethod,
    optionSearchEndpoint: props.optionSearchEndpoint,
    mandatory: props.mandatory
}

watch(props, () => {
    config = {...config, mandatory: props.mandatory}
})

</script>

