import axios from "@/misc/axios.js";
import store from "../misc/vuex-store.js";
import TenantConfigHandler from "@/misc/TenantConfigHandler";
import toast from "@/misc/toast.js";

export default {
  async fetchDocumentById(id) {
    const uri =
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
      `/rem/document/${id}/modify`;
    try {
      const response = await axios.get(uri, {
        onTokenRefreshed: () => {
          this.fetchDocumentById(id);
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  async fetchPremiseById(id) {
    const uri =
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
      `/rem/premise/${id}`;
    try {
      const response = await axios.get(uri, {
        onTokenRefreshed: () => {
          this.fetchPremiseById(id);
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  async fetchMeterById(id) {
    const uri =
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
      `/rem/meter/${id}`;
    try {
      const response = await axios.get(uri, {
        onTokenRefreshed: () => {
          this.fetchMeterById(id);
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  async fetchUtilizationById(id) {
    const uri =
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
      `/rem/utilization/${id}`;
    try {
      const response = await axios.get(uri, {
        onTokenRefreshed: () => {
          this.fetchUtilizationById(id);
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  async fetchContactById(id) {
    const uri =
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
      `/rem/partner/contact/${id}`;
    try {
      const response = await axios.get(uri, {
        onTokenRefreshed: () => {
          this.fetchContactById(id);
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  async fetchLegalSituationById(id) {
    const uri =
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
      `/rem/legal-situation/${id}`;
    try {
      const response = await axios.get(uri, {
        onTokenRefreshed: () => {
          this.fetchLegalSituationById(id);
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  async fetchBuildingById(id) {
    const uri =
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
      `/rem/building/${id}`;
    try {
      const response = await axios.get(uri, {
        onTokenRefreshed: () => {
          this.fetchBuildingById(id);
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  async fetchPropertyById(id) {
    const uri =
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
      `/rem/property/${id}`;
    try {
      const response = await axios.get(uri, {
        onTokenRefreshed: () => {
          this.fetchPropertyById(id);
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  async fetchPartners() {
    const uri =
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/partner";
    try {
      const response = await axios.get(uri, {
        onTokenRefreshed: () => {
          this.fetchPartners();
        },
      });
      return response.data.content;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
  async fetchPartnerById(id) {
    const uri =
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
      `/rem/partner/${id}`;
    try {
      const response = await axios.get(uri, {
        onTokenRefreshed: () => {
          this.fetchPartnerById(id);
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      return {};
    }
  },
  async storeAppConfig() {
    await axios
      .get(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/appconfig",
        {
          onTokenRefreshed: () => {
            this.storeAppConfig();
          },
        }
      )
      .then((response) => {
        store.commit("setAppConfig", response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  },
  async storeSiteDesigns() {
    try {
      if (!store.getters.getSiteDesigns) {
        const siteDesigns = await axios.get(
          TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
            "/site-design/",
          {
            onTokenRefreshed: () => {
              this.storeSiteDesigns();
            },
          }
        );
        store.commit("setSiteDesigns", siteDesigns.data);
      }

      return true;
    } catch (err) {
      console.log("ERR", err);
      return false;
    }
  },
  getSiteDesignById(siteDesignId) {
    const siteDesigns = store.getters.getSiteDesigns;

    if (!siteDesigns) {
      return null;
    }

    var result = null;
    siteDesigns.forEach((siteDesign) => {
      if (siteDesign.id == siteDesignId) {
        result = siteDesign;
      }
    });

    return result;
  },
  parseContentDispositionToFileName(disposition) {
    const filenameField = disposition
      .split(";")
      .map((x) => x.trim())
      .filter((x) => x.startsWith("filename"))
      .sort()?.[0];
    const rightPart = filenameField?.split("=")?.at(-1).replaceAll('"', "");
    if (filenameField?.startsWith("filename=")) {
      return rightPart;
    } else if (filenameField?.startsWith("filename*=")) {
      return decodeURIComponent(rightPart?.split("'")?.at(-1));
    }
    return "default";
  },
  roundToNearest15(date = new Date()) {
    const minutes = 15;
    const ms = 1000 * 60 * minutes;
    return new Date(Math.floor(date.getTime() / ms) * ms);
  },
  roundToNearest60(date = new Date()) {
    const minutes = 60;
    const ms = 1000 * 60 * minutes;
    return new Date(Math.floor(date.getTime() / ms) * ms);
  },
  roundToNearest1440(date = new Date()) {
    const minutes = 1440;
    const ms = 1000 * 60 * minutes;
    return new Date(Math.floor(date.getTime() / ms) * ms);
  },
  isModuleEnabled(moduleName) {
    return store.getters.isModuleEnabled(moduleName);
  },
  getPolyFillOpacityById() {
  //  minMaxCount, polyCount
    var colors = {
      lineColor: process.env.VUE_APP_POLYGON_LINE_COLOR,
      fillColor: process.env.VUE_APP_POLYGON_FILL_COLOR,
      inactiveLineColor: process.env.VUE_APP_INACTIVE_POLYGON_LINE_COLOR,
      inactiveFillColor: process.env.VUE_APP_INACTIVE_POLYGON_FILL_COLOR,
    };
    return colors.fill;

    // if(!isNaN(polyCount)) {
    //   return {
    //     fillOpacity: 0.1+((polyCount-minMaxCount.min)*((minMaxCount.max-minMaxCount.min)/100)/100*0.8),
    //     color: colors.fillColor,
    //     lineColor: colors.lineColor
    //   };
    // }
    // else {
    //   return {
    //     fillOpacity: 0.3,
    //     color: colors.inactiveFillColor,
    //     lineColor: colors.inactiveLineColor
    //   };
    // }
  },
  async fetchPolygons(siteDesignId) {
    if (!siteDesignId) return [];

    try {
      const response = await axios.get(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
          "/site-design/" +
          siteDesignId +
          "/zones_and_zone_groups",
        {
          onTokenRefreshed: () => {
            this.fetchPolygons(siteDesignId);
          },
        }
      );
      return response.data;
    } catch (err) {
      console.log(err);
      return [];
    }
  },
  async fetchZones(siteDesignId) {
    return await this.fetchPolygons(siteDesignId);
  },
  transformPointsToMap(y, x, mapDimensionsX, mapDimensionsY, mapGeometry) {
    var xRatio = mapDimensionsX / mapGeometry.width;
    var yRatio = mapDimensionsY / mapGeometry.length;

    x = x * xRatio;
    y = y * yRatio;

    return [y, x];
  },
  transformPointsToServer(y, x, mapDimensionsX, mapDimensionsY, mapGeometry) {
    var xRatio = mapGeometry.width / mapDimensionsX;
    var yRatio = mapGeometry.length / mapDimensionsY;
    return [yRatio * y, xRatio * x];
  },
  showToast(type, message) {
    if (this.toastInterval) {
      window.clearTimeout(this.toastInterval);
    }

    this.toast = {
      type: type,
      message: message,
    };

    var vm = this;
    vm.toastInterval = window.setTimeout(() => {
      vm.toast = null;
    }, 3000);
  },
  leftPad(str, length, padChar = ' ') {
    str = String(str); // Ensure input is a string
    if (str.length >= length) {
      return str;
    }
    return padChar.repeat(length - str.length) + str;
  },
  getMetaDataByTypeAndId(type, id, callback) {
    let url = "";
    if(type == "site-design")
    {
      url = TenantConfigHandler.getVariable("ADMINV2_API_URL") + "/site-design/" + id + "/metadatas";
    }
    if(type == "system")
    {
      url = TenantConfigHandler.getVariable("ADMINV2_API_URL") + "/system/metadatas";
    }
    axios.get(url, {
      onTokenRefreshed: () => {
        this.getMetaDataByTypeAndId(type, id, callback);
      }
    }).then(res => {
      if(callback) {
        callback(res);
      }
    }).catch(err => {
      console.log(err);
    });
  },
  getGistsByTypeAndId(type, id, callback) {
    let url = "";
    if (type == "zone") {
      url =
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/zone/" +
        id +
        "/gists";
    }

    if (type == "site-design") {
      url =
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/site-design/" +
        id +
        "/gists";
    }

    if (type == "zone-group") {
      url =
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/zone_group/" +
        id +
        "/gists";
    }

    if (type == "system") {
      url =
        TenantConfigHandler.getVariable("ADMINV2_API_URL") + "/system/gists";
    }

    axios
      .get(url, {
        onTokenRefreshed: () => {
          this.getGistsByTypeAndId(type, id, callback);
        },
      })
      .then((res) => {
        if (callback) {
          callback(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  getAggregationsByTypeAndId(type, id, callback) {
    let url = "";
    if (type == "zone") {
      url =
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/zone/" +
        id +
        "/aggregations";
    }

    if (type == "site-design") {
      url =
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/site-design/" +
        id +
        "/aggregations";
    }

    if (type == "zone-group") {
      url =
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/zone_group/" +
        id +
        "/aggregations";
    }

    axios
      .get(url, {
        onTokenRefreshed: () => {
          this.getAggregationsByTypeAndId(type, id, callback);
        },
      })
      .then((res) => {
        if (callback) {
          callback(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },

  /**
   *
   * @param {*} postendpoint - endpoint for post request
   * @param {*} patchendpoint - endpoint for patch request
   * @param {*} hermes - payload
   * @param {*} schema - formdata name
   *    schemaData : formdata field list
   *    schemaId : record id
   *    schemaEdit : readonly view Toggle
   * @param {*} tab - form tab to route back after succesful save
   * @param {*} state - state object of the form
   * @param {*} t - localization
   */

  patchPost: async function (
    postendpoint,
    patchendpoint,
    hermes,
    schema,
    tab,
    state,
    t
  ) {
    if (state.value[`${schema}Id`] != 0) {
      try {
        await axios
          .patch(patchendpoint, hermes, {
            onTokenRefreshed: () => {
              this.patchPost(
                postendpoint,
                patchendpoint,
                hermes,
                schema,
                tab,
                state,
                t
              );
            },
          })
          .then((resp) => {
            if (resp && resp.status == 200) {
              toast.success(t("message.successfulSave"));
              state.value[`${schema}Data`].forEach((e) => {
                e.value = "";
              });

              state.value.tab = tab;
              store.commit("setRemTab", tab);
              state.value[`${schema}Id`] = 0;
              state.value[`${schema}Edit`] = false;
              store.commit("setRemFormEdit", false);
            } else {
              toast.error(t("message.notSuccessfulSave"));
            }
          });
      } catch (e) {
        toast.error(t("message.notSuccessfulSave"));
      }
    } else {
      try {
        await axios
          .post(postendpoint, hermes, {
            onTokenRefreshed: () => {
              this.patchPost(
                postendpoint,
                patchendpoint,
                hermes,
                schema,
                tab,
                state,
                t
              );
            },
          })
          .then((resp) => {
            if (resp && resp.status == 201) {
              toast.success(t("message.successfulSave"));
              state.value[`${schema}Data`].forEach((e) => {
                e.value = "";
              });

              state.value.tab = tab;
              store.commit("setRemTab", tab);
              state.value[`${schema}Id`] = 0;
              state.value[`${schema}Edit`] = false;
              store.commit("setRemFormEdit", false);
            } else {
              toast.error(t("message.notSuccessfulSave"));
            }
          });
      } catch (e) {
        toast.error(t("message.notSuccessfulSave"));
      }
    }
  },

  /**
   *
   * @param {*} postendpoint - endpoint for post request
   * @param {*} putendpoint - endpoint for patch request
   * @param {*} hermes - payload
   * @param {*} schema - formdata name
   *    schemaData : formdata field list
   *    schemaId : record id
   *    schemaEdit : readonly view Toggle
   * @param {*} tab - form tab to route back after succesful save
   * @param {*} state - state object of the form
   * @param {*} t - localization
   */

  putPost: async function (
    postendpoint,
    putendpoint,
    hermes,
    schema,
    tab,
    state,
    t
  ) {
    if (state.value[`${schema}Id`] != 0) {
      try {
        await axios
          .put(putendpoint, hermes, {
            onTokenRefreshed: () => {
              this.putPost(
                postendpoint,
                putendpoint,
                hermes,
                schema,
                tab,
                state,
                t
              );
            },
          })
          .then((resp) => {
            if (resp && resp.status == 200) {
              toast.success(t("message.successfulSave"));
              state.value[`${schema}Data`].forEach((e) => {
                e.value = "";
              });

              state.value.tab = tab;
              store.commit("setRemTab", tab);
              state.value.confirmationModal.show = false;
              state.value[`${schema}Id`] = 0;
              state.value[`${schema}Edit`] = false;
              store.commit("setRemFormEdit", false);
            } else {
              toast.error(t("message.notSuccessfulSave"));
            }
          });
      } catch (e) {
        toast.error(t("message.notSuccessfulSave"));
      }
    } else {
      try {
        await axios
          .post(postendpoint, hermes, {
            onTokenRefreshed: () => {
              this.putPost(
                postendpoint,
                putendpoint,
                hermes,
                schema,
                tab,
                state,
                t
              );
            },
          })
          .then((resp) => {
            if (resp && resp.status == 201) {
              toast.success(t("message.successfulSave"));
              state.value[`${schema}Data`].forEach((e) => {
                e.value = "";
              });

              state.value.tab = tab;
              store.commit("setRemTab", tab);
              state.value.confirmationModal.show = false;
              state.value[`${schema}Id`] = 0;
              state.value[`${schema}Edit`] = false;
              store.commit("setRemFormEdit", false);
            } else {
              toast.error(t("message.notSuccessfulSave"));
            }
          });
      } catch (e) {
        toast.error(t("message.notSuccessfulSave"));
      }
    }
  },
  remDelete: async function (state, endpoint, formdata, tab, t) {
    try {
      await axios
        .delete(endpoint, {
          onTokenRefreshed: () => {
            this.deleteUniversal(state, endpoint, formdata, tab, t);
          },
        })
        .then((resp) => {
          if (resp && resp.status == 204) {
            toast.success(t("message.deleteSuccessful"));
            state.value.tab = tab;
            store.commit("setRemTab", tab);
            state.value[`${formdata}Id`] = 0;
            state.value[`${formdata}Edit`] = false;
            store.commit("setRemFormEdit", false);
          } else {
            toast.error(t("message.deleteNotSuccessful"));
          }
        });
    } catch (e) {
      toast.error(t("message.deleteNotSuccessful"));
    }
  },
  remGetOptions: function (
    source,
    target,
    isThereWeirdFormattingForOption
  ) {
    target.forEach((e) => {
      if (e.type == "label") {
        return;
      }

      if (e.type == "option" && isThereWeirdFormattingForOption == true) {
        if (source[e.model + "Id"]) {
          e.value = source[e.model + "Id"];
          e.valueLabel = source[e.model + "Value"];
        }
      } else if (e.type == "checkbox" && source[e.model] == null) {
        e.value = "";
      } else {
        e.value = source[e.model];
      }
    });
  },
  
  toBoolean(value) {
    const str = String(value).toLowerCase();
    if (str === "true") {
        return true;
    } else if (str === "false") {
        return false;
    } else {
        return null;
    }
  },
  async getOptions(optionValue) {
    const response = await axios.get(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
      "/rem/option/options/" +
      optionValue,
      {
          'Accept-Language': store.getters.getLanguage
      },
      {
          onTokenRefreshed: () => {
              this.getOptions(optionValue);
          },
      }
    )
    return response.data;
  }
};
