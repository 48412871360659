<template>
    <div>
        <!-- Modal toggle -->
        <input type="checkbox" class="modal-toggle" v-model="state.showModal" />
        <div class="modal">
            <div class="modal-box">
                <div class="h-full overflow-y-auto w-full">
                    <div class="mt-5 mx-5 flex flex-row grow border-b-0 border-base-300 justify-between">
                        <div class="flex flex-row items-center gap-2 justify-center">
                            <h2
                                class="text-base font-sans font-semibold uppercase text-secondary flex flex-row items-center gap-2 grow">
                                <span class="text-secondary">{{
                                    !props.editMode ? $t("message.addNew") : $t("message.edit")
                                    }}</span>
                            </h2>
                        </div>
                        <a href="javascript:void(null)" class="hover:text-secondary" @click="closeModal">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </a>
                    </div>

                    <div class="px-5 pb-5">
                        <div class="flex flex-col gap-2 w-full">
                            <!--eslint-disable-->
                            <div class="flex flex-row gap-3 w-full mt-2">
                                <ImFormInput v-model:inputValue="state.name" :title="$t('message.name')" type="text"
                                    class="w-full mr-4">
                                </ImFormInput>
                            </div>

                            <!-- <div :class="{ hideOpenIndicator: state.fromStateId || state.toStateId }">
                                <RemLabel class="mt-2" :title="t('message.fromState')"></RemLabel>
                                <div class="dropdown w-full">
                                    <select class="input input-sm input-bordered w-full pr-2"
                                        v-model="state.fromStateId">
                                        <option v-for="state in props.states" v-bind:key="state.id" :value="state.id">{{
                                            state.name }}
                                        </option>
                                    </select>
                                    <button v-if="state.fromStateId" class="clear-button"
                                        @click="state.fromStateId = null" aria-label="Törlés">
                                        <span class="text-sm bold">
                                            X
                                        </span>
                                    </button>
                                </div>
                            </div> -->

                            <div :class="{ hideOpenIndicator: state.fromStateId || state.toStateId }">
                                <RemLabel class="mt-2" :title="t('message.toState')"></RemLabel>
                                <div class="dropdown">
                                    <select class="input input-sm input-bordered w-full pr-2" v-model="state.toStateId">
                                        <option v-for="state in props.states" v-bind:key="state.id" :value="state.id">{{
                                            state.name }}
                                        </option>
                                    </select>
                                    <button v-if="state.toStateId" class="clear-button" @click="state.toStateId = null"
                                        aria-label="Törlés">
                                        <span class="text-sm bold">
                                            X
                                        </span>
                                    </button>
                                </div>
                            </div>


                            <div class="flex flex-row gap-3 w-full mt-2">
                                <ImFormInput v-model:inputValue="state.workflowActionCode"
                                    :title="$t('message.workflowActionCode')" type="text" class="w-full mr-4">
                                </ImFormInput>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-action">
                    <label class="btn btn-sm bg-magenta text-white font-light text-xs" @click="() => {
                        saveTransition();
                        this.$emit('refresh')
                        store.commit('setIsTrModalOpen', false);
                    }
                        ">{{ $t("message.save") }}</label>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, reactive, watch } from "vue";
import Axios from "@/misc/axios";
import toast from "@/misc/toast.js";
import store from "@/misc/vuex-store.js";
import { useI18n } from "vue-i18n";
import TenantConfigHandler from "@/misc/TenantConfigHandler";
import { useRoute } from "vue-router";
import ImFormInput from "@/components/ImFormInput.vue";
import RemLabel from "@/components/REM/imFormInput/RemLabel.vue"

const { t } = useI18n();
const router = useRoute();
const taskTypeId = router.params.taskTypeId
const workflowId = router.params.workflowId

const emits = defineEmits(['refresh'])
const props = defineProps(["showModal", "id", 'states', 'transitionById', 'editMode']);

const state = reactive({
    showModal: false,
    fromStateId: null,
    name: null,
    toStateId: null,
    workflowActionCode: null
});

const saveTransition = async () => {
    let uri = ''
    const hermes = {
        fromStateId: state.fromStateId,
        toStateId: state.toStateId,
        actionName: state.name,
        workflowActionCode: state.workflowActionCode
    }

    if (!props.editMode) {
        uri = TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/workflow/admin/task-type/${taskTypeId}/workflow/${workflowId}/transitions`

        try {
            await Axios.post(uri, hermes, {
                onTokenRefreshed: () => {
                    saveTransition()
                }
            })

            toast.success(t("message.successfulSave"))
            emptyForm()
            emits('refresh')
        } catch (error) {
            console.log(error);
            toast.error(t("message.notSuccessfulSave"))
        }
    } else if (props.editMode) {
        uri = TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/workflow/admin/task-type/${taskTypeId}/workflow/${workflowId}/transition/${props.transitionById.id}`

        try {
            await Axios.put(uri, hermes, {
                onTokenRefreshed: () => {
                    saveTransition()
                }
            })

            toast.success(t("message.successfulSave"))
            emptyForm()
            emits('refresh')
        } catch (error) {
            console.log(error);
            toast.error(t("message.notSuccessfulSave"))
        }
    }
}

const fillForm = () => {
    state.name = props.transitionById.actionName
    state.fromStateId = props.transitionById.fromStateId
    state.toStateId = props.transitionById.toStateId
    state.workflowActionCode = props.transitionById.workflowActionCode
}

watch(
    () => props.showModal,
    () => {
        state.showModal = props.showModal;
        state.fromStateId = props.id;
    }
);

watch(() => props.editMode,
    () => {
        props.editMode ? fillForm() : emptyForm();
    })

const closeModal = () => {
    store.commit('setIsTrModalOpen', false);
}

const emptyForm = () => {
    state.name = null
    state.fromStateId = null
    state.toStateId = null
    state.workflowActionCode = null
}

onMounted(() => {

})
</script>

<style scoped>
select[disabled] {
    @apply border border-gray-600;
}

.im-dropdown-content {
    position: absolute
}

.im-invisible {
    display: none;

}

.dropdown {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 96%;
}

.clear-button {
    position: absolute;
    right: 0.6rem;
    background: none;
    border: none;
    color: rgb(175, 170, 170);
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    line-height: 1;
}

.clear-button:hover {
    cursor: pointer;
}

.hideOpenIndicator .dropdown::before {
    display: none;
}

.dropdown::before {
    position: absolute;
    font-size: 12px;
    content: " \25BC";
    top: 7px;
    right: -8px;
    height: 20px;
    width: 28px;
    pointer-events: none;
}

select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.option-hover {
    padding: 5px 0 5px 5px;
    font-size: 0.875rem;

}

.option-hover:hover {
    background-color: rgb(147, 185, 247);
    color: #242933;
}

ul .option-hover:first-of-type {
    border-radius: 10px 10px 0 0;
}

ul .option-hover:last-of-type {
    border-radius: 0 0 10px 10px;
}
</style>